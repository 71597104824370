/* ========================================================================
 * DOM-based Routing
 * Based on http://goo.gl/EUTi53 by Paul Irish
 *
 * Only fires on body classes that match. If a body class contains a dash,
 * replace the dash with an underscore when adding it to the object below.
 *
 * .noConflict()
 * The routing is enclosed within an anonymous function so that you can
 * always reference jQuery with $, even when in .noConflict() mode.
 * ======================================================================== */

(function ($) {
  var map = null;
  var markers = [];
  var $grid = null;
  var controller = new ScrollMagic.Controller();
  var ease = Expo.easeInOut;
  $.fn.replace_html = function (html) {
    return this.each(function () {
      var el = $(this);
      el.stop(true, true, false);
      el.css({ height: "auto", opacity: 0 });
      var cur = { height: el.outerHeight() + "px" };
      el.html(html);
      var finish = { height: el.outerHeight() + "px", opacity: 1 };
      el.css(cur).animate(finish, 750);
    });
  };

  $.fn.randomize = function (childElem) {
    return this.each(function () {
      var $this = $(this);
      var elems = $this.children(childElem);

      elems.sort(function () {
        return (Math.round(Math.random()) - 0.5);
      });

      $this.detach(childElem);

      for (var i = 0; i < elems.length; i++) {
        $this.append(elems[i]);
      }
    });
  };



  // Use this variable to set up the common and page specific functions. If you
  // rename this variable, you will also need to rename the namespace below.
  var Sage = {
    'base_url': document.head.querySelector("[name=base_url]").content,
    'common': {
      init: function () {
        $(document).foundation(); // Foundation JavaScript

        Sage.common.mobile_nav();
        Sage.common.wow();
        Sage.common.parallaxBg();

        if ($('.acf-map').length) {
          $('.acf-map').each(function () {
            Sage.common.new_map($(this));
          });
        }
      },
      zoom: 16,
      new_map: function ($el) {
        var $markers = $('.marker');
        var args = {
          zoom: Sage.common.zoom,
          center: new google.maps.LatLng(0, 0),
          mapTypeId: google.maps.MapTypeId.ROADMAP,
          styles: [{ "featureType": "administrative", "elementType": "all", "stylers": [{ "lightness": "0" }, { "gamma": "1.00" }, { "visibility": "simplified" }] }, { "featureType": "administrative", "elementType": "labels.text.fill", "stylers": [{ "color": "#959595" }] }, { "featureType": "landscape", "elementType": "all", "stylers": [{ "color": "#f2f2f2" }] }, { "featureType": "poi", "elementType": "all", "stylers": [{ "visibility": "off" }] }, { "featureType": "road", "elementType": "all", "stylers": [{ "saturation": -100 }, { "lightness": 45 }, { "visibility": "simplified" }] }, { "featureType": "road.highway", "elementType": "all", "stylers": [{ "visibility": "simplified" }] }, { "featureType": "road.highway", "elementType": "geometry.fill", "stylers": [{ "color": "#ffffff" }] }, { "featureType": "road.arterial", "elementType": "labels.icon", "stylers": [{ "visibility": "off" }] }, { "featureType": "transit", "elementType": "all", "stylers": [{ "visibility": "off" }] }, { "featureType": "water", "elementType": "all", "stylers": [{ "color": "#dde6e8" }, { "visibility": "on" }] }],
        };
        map = new google.maps.Map($el[0], args);
        map.markers = [];
        Sage.common.add_marker($markers.first(), map);
        Sage.common.center_map(map);
        return map;
      },
      clear_marker: function (map) {
        for (var i = 0; i < map.markers.length; i++) {
          map.markers[i].setMap(null);
        }
        map.markers = [];
      },
      add_marker: function ($marker, map) {
        var icon = {
          path: "M36.7 18.4C36.7 8.2 28.5 0 18.4 0A18.42 18.42 0 0 0 3.5 29.2l14.8 25.7 16.5-28.3h-.1c1.3-2.5 2-5.3 2-8.2zM18.4 23c-2.9 0-5.2-2.3-5.2-5.2s2.3-5.2 5.2-5.2 5.2 2.3 5.2 5.2-2.4 5.2-5.2 5.2z",
          fillColor: '#3d576e',
          fillOpacity: 1,
          anchor: new google.maps.Point(18, 54),
          strokeWeight: 0,
          scale: 1
        };
        var latlng = new google.maps.LatLng($marker.attr('data-lat'), $marker.attr('data-lng'));
        var marker = new google.maps.Marker({
          position: latlng,
          icon: icon,
          map: map
        });
        map.markers.push(marker);
        if ($marker.html()) {
          var infowindow = new google.maps.InfoWindow({
            content: $marker.html()
          });
          google.maps.event.addListener(marker, 'click', function () {
            infowindow.open(map, marker);
          });
        }
      },
      center_map: function (map) {
        var bounds = new google.maps.LatLngBounds();
        $.each(map.markers, function (i, marker) {
          var latlng = new google.maps.LatLng(marker.position.lat(), marker.position.lng());
          bounds.extend(latlng);
        });
        if (map.markers.length === 1) {
          map.setCenter(bounds.getCenter());
          map.setZoom(Sage.common.zoom);
        } else {
          map.fitBounds(bounds);
        }
      },
      mobile_nav_open: function () {
        $('.hamburger').addClass('active');
        $('body').addClass('menu_open');
      },
      mobile_nav_close: function () {
        $('.hamburger').removeClass('active');
        $('body').removeClass('menu_open');
      },
      mobile_nav: function () {
        $('.hamburger, .mobile-nav__overlay').click(function () {
          if ($('body').hasClass('menu_open')) {
            Sage.common.mobile_nav_close();
          } else {
            Sage.common.mobile_nav_open();
          }
          return false;
        });
      },
      wow: function () {
        // set the controller for animations
        var duration = 1;

        // single wow - fadeIn & fadeInUp 
        $('.wow').each(function (i) {
          var tl = new TimelineMax();
          var delay = $(this).data('delay') || 0;
          var t1_duration = $(this).data('duration') || duration;

          var animation = { ease: ease };

          if ($(this).hasClass('fadeIn')) {
            animation.ease = ease;
            animation.autoAlpha = 0;
            tl.from($(this), t1_duration, animation, delay);
          }

          if ($(this).hasClass('fadeInUp')) {
            animation.ease = ease;
            animation.autoAlpha = 0;
            animation.y = 30;
            tl.from($(this), t1_duration, animation, delay);
          }

          if ($(this).hasClass('fadeInSlideDown')) {
            animation.autoAlpha = 0;
            animation.y = '-100%';
            tl.from($(this), t1_duration, animation, delay);
          }


          new ScrollMagic.Scene({
            triggerElement: this,
            triggerHook: 1,
            reverse: false
          })
            .setTween(tl) // trigger a TweenMax.to tween
            .addTo(controller);
        });

        // series wow - fadeIn & fadeInUp 
        $('.wows').each(function (i) {
          var tl = new TimelineMax();
          var delay = $(this).data('delay') || 0;
          var t1_duration = $(this).data('duration') || duration;

          var animation = { ease: ease };

          if ($('.fadeIn', this)) {
            animation.ease = ease;
            animation.autoAlpha = 0;
            tl.staggerFrom($('.fadeIn', this), t1_duration, animation, 0.05, delay);
          }

          if ($('.fadeInUp, > .menu-item', this)) {
            animation.ease = ease;
            animation.autoAlpha = 0;
            tl.staggerFrom($('.fadeInUp, > .menu-item', this), t1_duration, animation, 0.05, delay);
          }

          new ScrollMagic.Scene({
            triggerElement: this,
            triggerHook: 1,
            reverse: false
          })
            .setTween(tl) // trigger a TweenMax.to tween
            .addTo(controller);
        });
      },
      parallaxBg: function () {
        var $bcg = $('.bcg');
        var $page = $('.page-content');
        if ($bcg.length > 0) {
          var bpos = $bcg.css('backgroundPosition').split(" ");
          var y = parseInt(bpos[1]);
          var offset_y = y + 100;
          var slideParallaxScene = new ScrollMagic.Scene({
            triggerElement: $page,
            triggerHook: 1,
            duration: "200%"
          })
            .setTween(TweenMax.to($bcg, 1, { backgroundPosition: '100% ' + offset_y + '%', ease: Power0.easeNone }))
            .addTo(controller);
        }
      }
    },
    'team': {
      init: function () {
        console.log('hi');
        $(".team-list .row").randomize(".team-list__member");
        var sticky = $('.team-list__member--sticky');
        if (sticky) {
          $('.team-list__member--sticky').remove();
          $(".team-list .row").prepend(sticky);
        }
      }
    }
  };

  // The routing fires all common scripts, followed by the page specific scripts.
  // Add additional events for more control over timing e.g. a finalize event
  var UTIL = {
    fire: function (func, funcname, args) {
      var fire;
      var namespace = Sage;
      funcname = (funcname === undefined) ? 'init' : funcname;
      fire = func !== '';
      fire = fire && namespace[func];
      fire = fire && typeof namespace[func][funcname] === 'function';

      if (fire) {
        namespace[func][funcname](args);
      }
    },
    loadEvents: function () {
      // Fire common init JS
      UTIL.fire('common');

      // Fire page-specific init JS, and then finalize JS
      $.each(document.body.className.replace(/-/g, '_').split(/\s+/), function (i, classnm) {
        UTIL.fire(classnm);
        UTIL.fire(classnm, 'finalize');
      });

      // Fire common finalize JS
      UTIL.fire('common', 'finalize');
    }
  };

  // Load Events
  $(document).ready(UTIL.loadEvents);

})(jQuery); // Fully reference jQuery after this point.
